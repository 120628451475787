import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import styles from './SelectionStatus.scss';

function SelectionStatus({ linksCount }) {
  const { confirmedProducts } = useSelector(state => state.ngiContentSelector);

  const hasDeepLinks = confirmedProducts.find(product => product.addContentItems);

  if (linksCount === 0) {
    return <div className={styles.message}>You have no items selected</div>;
  }

  if (!hasDeepLinks) {
    return null;
  }

  return (
    <div className={styles.container}>
      Learning management systems have a limit on the number of deep links that can be created at a time. If the deep
      link creation fails, try reducing the number of links.
      <a href="https://example.com" target="_blank" rel="noreferrer" className={styles.link}>
        LMS import limits
        <span className={styles.icon}>
          <SVGIcon viewBox="0 0 12 12" glyph={GLYPHS.ICON_EXTERNAL_LINK} />
        </span>
      </a>
    </div>
  );
}

SelectionStatus.propTypes = {
  linksCount: PropTypes.number
};

export default SelectionStatus;
