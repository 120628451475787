import React from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../../../components/PanelHeading/PanelHeading';
import styles from './NoResultsFound.scss';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { GlyphIcon } from '../../../components/IconEmblem/IconEmblem';

function NoResultsFound({ onResetFiltersClick }) {
  return (
    <div className={styles.container}>
      <img alt="No results found" src="/static/images/ngi/redeem-image.jpeg" />
      <PanelHeading
        customClassName={styles.panel}
        title="No results found"
        subtitle="You may need to remove some filters or try another option"
      />
      <button onClick={onResetFiltersClick} className={styles.reset} type="button">
        <SVGIcon className={styles.svg} glyph={GlyphIcon.ICON_REFRESH_CIRCLE} />
        Reset filters
      </button>
    </div>
  );
}

NoResultsFound.propTypes = {
  onResetFiltersClick: PropTypes.func.isRequired
};

export default NoResultsFound;
