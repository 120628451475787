import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput.js';

function ContentSelectorSearchInput({ placeholder, onValueChange, value }) {
  const handleSearchChange = newValue => {
    onValueChange(newValue);
  };

  return (
    <div style={{ margin: '15px 15px 0px 15px' }}>
      <TextInput
        id="input"
        placeholder={placeholder}
        label={placeholder}
        value={value}
        hideLabel
        onChange={event => handleSearchChange(event.target.value)}
        inputmode="search"
        autocomplete="on"
        required={false}
      />
    </div>
  );
}

ContentSelectorSearchInput.propTypes = {
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default ContentSelectorSearchInput;
