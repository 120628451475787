import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '@oup/shared-front-end/src/components/Checkbox/Checkbox.js';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { ICON_RIGHT } from '@oup/shared-front-end/src/svg/oup/index.js';
import { useDispatch } from 'react-redux';
import HubLabel from '../../../components/HubLabel/HubLabel.js';
import styles from './SelectionLineItem.scss';
import HubDropdownMenu from '../../../components/HubDropdownMenu/HubDropdownMenu.js';
import fallbackImage from '../components/fallback-image.png';
import { showProductDetailsModal } from '../../../redux/actions/ngiContentSelectorActions.js';

function ContentSelectorSingleItem({
  acceptMultiple,
  product,
  onProductSelected,
  onProductDeselected,
  isProductSelected,
  onSingleProductSelected
}) {
  const dispatch = useDispatch();
  const { productid: productId } = product;

  const dropdownActions = {
    menuButtonAriaLabel: 'Content selector dropdown',
    menuPanelAriaLabel: 'Content selector panel menu',
    menuItems: [
      {
        title: 'View product information',
        action: () => {
          dispatch(showProductDetailsModal(product));
        }
      }
    ]
  };

  const handleProductSelection = () => {
    if (isProductSelected) onProductDeselected(product);
    else onProductSelected(product);
  };

  const targetUserType = product.target_usertype === 'teacher' ? 'Teacher material' : 'Student material';

  const isNotOxfordEnglishHub = product.isbn !== '-';

  const addFallbackCover = ev => {
    ev.target.src = fallbackImage;
  };

  return acceptMultiple ? (
    <li className={styles.list} key={productId}>
      <div className={styles.info}>
        <div className={styles.checkbox}>
          <Checkbox
            id={productId}
            checked={isProductSelected}
            label={`select ${product.title}`}
            value={`select ${product.title}`}
            onChange={() => handleProductSelection()}
            hideLabel
          />
        </div>
        <div className={styles.broken}>
          <img src={product.cover} alt="" onError={addFallbackCover} />
        </div>
        <div className={isNotOxfordEnglishHub ? styles.label : classNames(styles.label, styles.oxfordHubItem)}>
          {isNotOxfordEnglishHub && <HubLabel customClassName={styles.confirmSingleLabel} text={targetUserType} />}
          <p className={styles.title}>{product.title}</p>
        </div>
      </div>
      <div>
        <HubDropdownMenu
          parentId={productId}
          dropdownTop={false}
          dropdownData={dropdownActions}
          customClassname={styles.iconMenu}
          panelCustomClassname={styles.menu}
        />
      </div>
    </li>
  ) : (
    <li className={styles.list} key={productId}>
      <div className={styles.info}>
        <div className={styles.broken}>
          <img src={product.cover} alt="" onError={addFallbackCover} />
        </div>
        <div className={isNotOxfordEnglishHub ? styles.label : classNames(styles.label, styles.oxfordHubItem)}>
          {isNotOxfordEnglishHub && <HubLabel customClassName={styles.confirmSingleLabel} text={targetUserType} />}
          <p className={styles.title}>{product.title}</p>
        </div>
      </div>
      <div>
        <Button
          variant="filled"
          size="base"
          text={`Select ${product.title}`}
          hideText
          icon={{
            placement: 'left',
            component: <ICON_RIGHT />
          }}
          onClick={() => onSingleProductSelected(product)}
        />
      </div>
    </li>
  );
}

ContentSelectorSingleItem.propTypes = {
  acceptMultiple: PropTypes.bool,
  product: PropTypes.object,
  onProductSelected: PropTypes.func,
  onProductDeselected: PropTypes.func,
  onSingleProductSelected: PropTypes.func,
  isProductSelected: PropTypes.bool
};

export default ContentSelectorSingleItem;
