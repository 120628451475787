import React from 'react';
import PropTypes from 'prop-types';
import { usePagination, DOTS } from './usePagination';
import styles from './Pagination.scss';

function Pagination({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={styles.container}>
      <button
        type="button"
        className={`${styles.pagination} ${currentPage === 1 ? styles.disabled : ''} ${styles.leftCorner}`}
        onClick={onPrevious}
      >
        <div className={`${styles.arrow} ${styles.left}`} />
      </button>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <button type="button" key={index} className={`${styles.pagination} ${styles.dots}`}>
              &#8230;
            </button>
          );
        }

        return (
          <button
            key={index}
            type="button"
            className={`${styles.pagination} ${pageNumber === currentPage ? styles.selected : ''}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        type="button"
        className={`${styles.pagination} ${currentPage === lastPage ? styles.disabled : ''} ${styles.rightCorner}`}
        onClick={onNext}
      >
        <div className={`${styles.arrow} ${styles.right}`} />
      </button>
    </div>
  );
}

Pagination.propTypes = {
  siblingCount: PropTypes.string,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number
};

export default Pagination;
