import React from 'react';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './CreateDeepLinksPage.scss';
import SuccessIllustration from './illus-task-success.png';

function CreateDeepLinksPage({ submitData }) {
  const { productsWithGradeItems } = useSelector(state => state.processLineItemsBatches);
  const pageTitle = productsWithGradeItems && productsWithGradeItems.length ? 'All grade items created' : 'All set';
  setTimeout(() => submitData(), 5000);
  return (
    <>
      <img className={styles.successIllustration} src={SuccessIllustration} alt="Success Task Illustration" />
      <h1 className={styles.title}>{pageTitle}</h1>
      <div className={styles.linkCreationDescription}>Link creation will start automatically in 5 seconds</div>
      <Button onClick={submitData} variant="filled" text="Start link creation" size="base" />
    </>
  );
}

CreateDeepLinksPage.propTypes = {
  submitData: PropTypes.func
};

export default CreateDeepLinksPage;
